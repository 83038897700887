var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/app/help-search/create')}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add new help search ")],1),_c('json-excel',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"\n          export\n          v-btn\n          v-btn--is-elevated\n          v-btn--has-bg\n          theme--light\n          elevation-2\n          v-size--default",attrs:{"name":"hulpzoeker.xls","data":_vm.getHelpSearches,"fields":_vm.exportFields,"before-finish":_vm.logEvent}},[_vm._v(" Export as excel ")])],1),_c('v-card-title',[(_vm.page)?[_vm._v(" "+_vm._s(_vm.page.title)+" ")]:_vm._e(),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [-1, 15, 30, 50, 100] },"items":_vm.headers.length ? _vm.getHelpSearches : [],"item-key":"id","multi-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push(("/app/help-search/" + (item.id) + "/edit"))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Delete help search")]),_c('v-card-text',[_vm._v(" The help search will be deleted permanently. Are you sure? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"danger","text":"","loading":_vm.getHelpSearchLoading},on:{"click":_vm.deleteHelpSearch}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }