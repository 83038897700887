<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            class="ma-2"
            dark
            color="primary"
            @click="$router.push('/app/help-search/create')"
          >
            <v-icon>mdi-plus</v-icon>
            Add new help search
          </v-btn>
          <json-excel
            class="
            export
            v-btn
            v-btn--is-elevated
            v-btn--has-bg
            theme--light
            elevation-2
            v-size--default"
            name="hulpzoeker.xls"
            :data="getHelpSearches"
            :fields="exportFields"
            :before-finish="logEvent"
            v-ripple
          >
            Export as excel
          </json-excel>
        </v-card-title>
        <v-card-title>
          <template v-if="page">
            {{ page.title }}
          </template>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :footer-props="{ 'items-per-page-options': [-1, 15, 30, 50, 100] }"
          :items="headers.length ? getHelpSearches : []"
          item-key="id"
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$router.push(`/app/help-search/${item.id}/edit`)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="confirmDelete(item.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="deleteDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h6">Delete help search</v-card-title>
          <v-card-text>
            The help search will be deleted permanently. Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="deleteDialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="danger"
              text
              :loading="getHelpSearchLoading"
              @click="deleteHelpSearch"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { create } from "@/services/eventService";
import store from "@/store";
import _ from "lodash";
import moment from "moment";
import JsonExcel from "vue-json-excel";

export default {
  metaInfo: {
    title: "Help search"
  },
  components: { JsonExcel },
  data() {
    return {
      model: "help-search",
      page: null,
      search: "",
      selectedId: null,
      headers: [
        { text: "Company", value: "company_name" },
        { text: "Address", value: "address" },
        { text: "Zip code", value: "zipcode" },
        { text: "City", value: "city" },
        { text: "Action", value: "action", sortable: false }
      ],
      exportFields: {
        Naam: "company_name",
        Adres: {
          callback: value => `${value.address}, ${value.zipcode}, ${value.city}`
        },
        Phone: {
          callback: value => (value.phone ? `​${value.phone}` : "")
        },
        "Type behandeling": {
          callback: value =>
            _.chain(value.treatment_types)
              .map(item => item.name)
              .join(";")
              .value()
        },
        Vergoed: "compensated",
        "Eigen risico": "own_risk",
        "Eigen bijdrage": "own_contribution",
        Verslavingen: {
          callback: value =>
            _.chain(value.addictions)
              .map(item => item.name)
              .join(";")
              .value()
        },
        Website: "website",
        "Voor naasten": "voor_naasten",
        "MDFT (mdft.nl)": {
          callback: value => (value.mdft ? "<center>x</center>" : "")
        },
        CogGedTh: {
          callback: value => (value.coggedth ? "<center>x</center>" : "")
        },
        "MGV (Motiverende gespreksvoering)": {
          callback: value => (value.mgv ? "<center>x</center>" : "")
        },
        Systeemtherapie: {
          callback: value => (value.systeemtherapie ? "<center>x</center>" : "")
        },
        "IRB (Individuele Rehabilitatie Benadering)": {
          callback: value => (value.irb ? "<center>x</center>" : "")
        },
        "12 stappen (Minnesota)": {
          callback: value => (value.twaalf_stappen ? "<center>x</center>" : "")
        },
        Schematherapie: {
          callback: value => (value.schematherapie ? "<center>x</center>" : "")
        },
        "Psycho-educatie": {
          callback: value => (value.psycho_educatie ? "<center>x</center>" : "")
        },
        ACT: {
          callback: value => (value.act ? "<center>x</center>" : "")
        },
        "CRA (ComntyReinfAppr)": {
          callback: value => (value.cra ? "<center>x</center>" : "")
        },
        EMDR: {
          callback: value => (value.emdr ? "<center>x</center>" : "")
        },
        Psychotherapie: {
          callback: value => (value.psychotherapie ? "<center>x</center>" : "")
        },
        Mindfulness: {
          callback: value => (value.mindfulness ? "<center>x</center>" : "")
        },
        Terugvalpreventie: {
          callback: value =>
            value.terugvalpreventie ? "<center>x</center>" : ""
        }
      },
      deleteDialog: false
    };
  },
  mounted() {
    this.page = store.getters.getPage({ model: this.model });
  },
  methods: {
    ...mapActions(["removeHelpSearch"]),
    confirmDelete(id) {
      this.selectedId = id;
      this.deleteDialog = true;
    },
    async deleteHelpSearch() {
      await this.removeHelpSearch(this.selectedId);
      this.deleteDialog = false;
    },
    async logEvent() {
      try {
        await create({
          code: "HELP_SEARCH_EXPORT",
          description: "Help search was exported as excel."
        });
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    ...mapGetters(["getHelpSearches", "getHelpSearchLoading"])
  },
  filters: {
    datetime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>
<style lang="scss" scoped>
.export:hover {
  cursor: pointer;
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
